/* @jsx jsx */
import { Box, Heading, jsx } from 'theme-ui'
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { UpsellCard } from './UpsellCard'
import { useUpsell } from './useUpsell'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

export const CartUpsell = ({ upsell }) => {
  const upsellItems = useUpsell(upsell)
  const translate = useTranslate()
  const [addedId, setAddedId] = useState(null);
  if (!upsellItems?.length) {
    return null // Hide upsell if the customer is buying everything
  }

  return (
    <Box sx={{ pt: '32px', maxWidth: '600px' }}>
      <Heading
        sx={{
          color: 'primary',
          fontSize: ['16px', '20px'],
          lineHeight: '125%',
          fontFamily: 'heading',
          mb: '20px',
          textTransform: 'uppercase'
        }}
      >
        {translate('cart.upsell_title')}
      </Heading>
      <Swiper
        grabCursor
        slidesPerView={'auto'}
        sx={{
          cursor: 'grab',
          '.swiper-slide': {
            maxWidth: ['264px', '296px'],
            '&:first-of-type': {
              marginRight: '8px'
            }
          },
          '.upsell-card': {
            position: 'relative',
            transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
            opacity: '0',
            '&.shown': {
              opacity: '1',
            },
            '&.hidden': {
              transform: 'translateY(-100%)',
              opacity: '0',
              pointerEvents: 'none'
            },
            '&.hidden-complete': {
              display: 'none'
            }
          }
        }}
      >
        {upsellItems.filter(item => item?.id && item.id !== addedId).slice(0, 2).map(item => (
          <SwiperSlide key={item.id}>
            <UpsellCard item={item} onAdd={(() => {
              setAddedId(item.id)
            })} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
