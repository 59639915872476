/* @jsx jsx */
import React from 'react'
import { jsx, Grid, Box, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const SubBlurb = ({ isMobile }) => {
  const translate = useTranslate()
  return (
    <Grid
      sx={{
        m: 0,
        p: 0,
        width: '100%',
        m: 0,
        mt: ['24px', '16px'],
      }}
    >
      <Box
        sx={{
          py: isMobile ? '8px' : '12px',
          px: isMobile ? '12px' : '20px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          borderColor: 'primary',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <Text
          as="p"
          sx={{
            color: 'primary',
            fontSize: isMobile ? '12px' : '16px',
            lineHeight: isMobile ? '16px' : '20px',
            width: isMobile ? '87.5%' : '100%',
            textAlign: 'left',
            mx: 'auto',
            maxWidth: !isMobile ? '43ch' : '100%',
          }}
        >
          {translate('cart.subscription_notice')}
        </Text>
      </Box>
    </Grid>
  )
}
export default SubBlurb
