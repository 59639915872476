import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { useEffect } from 'react'
import React from 'react'
import { useRef } from 'react'

export function useUpsell(items) {
  const [upsellItems, setUpsellItems] = React.useState((items || []).slice(0, 3))
  const { cart } = useCart()
  const { data, isFetching } = cart

  function sortItems() {
    setUpsellItems(() => {
      if (isFetching) {
        return items.slice(0, 3)
      }

      const productSlugs = (data?.lineItems || []).map(item => item.variant.slug)
      return items.filter(item => !productSlugs.includes(item.product[0].slug))
    })
  }

  useEffect(() => {
    sortItems()
  }, [items, data?.lineItems])

  return upsellItems
}
