/* @jsx jsx */
import React, { useEffect, useRef } from 'react'
import { useAnalytics, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Flex, Grid, Box, Heading, Text, Button, jsx, Link } from 'theme-ui'
import Img from 'gatsby-image'
import { trackProductClicked } from '@chordcommerce/gatsby-theme-autonomy/src/services/analytics'
import { toURL } from '~/utils/intl/localePrefix'

export const UpsellCard = ({ item, onAdd }) => {
  const { trackClickProduct } = useAnalytics();
  const translate = useTranslate()

  const { price, sku, mainImage, optionValues, product } = item
  const [{ slug, name }] = product
  const ref = useRef(null);
  const isVarietyPack = slug === 'variety-pack-product';
  const pageSlug = toURL(isVarietyPack ? '/bundles/variety-pack' : `/products/${slug}`)

  useEffect(() => {
    if (ref.current) {
      ref.current.classList.add('shown');
    }
  }, [ref])

  const handleAdd = async (sku) => {
    if (!ref.current) return;
    ref.current.classList.add('hidden')
    setTimeout(() => {
      ref.current.classList.add('hidden-complete')
      ref.current.classList.remove('hidden')
      if (onAdd) {
        onAdd();
      }
    }, 500)
    
    setTimeout(() => window.dkAddToCart(sku, 1, false), 50);
  }

  return (
    <Flex
      ref={ref}
      className="upsell-card"
      sx={{
        minWidth: '264px',
        width: '100%',
        maxWidth: '296px',
        minHeight: '148px',
        border: '1px solid #FF5A4733',
        borderRadius: '10px',
        p: '20px',
        flexDirection: 'column',
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: '25% 75%',
          flex: 1,
          mb: '20px'
        }}
      >
        <Box>
          <Box
            sx={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '2px solid #FFDD00',
              mt: '-1px'
            }}
          >
            <Img fluid={mainImage.fluid} />
          </Box>
        </Box>
        <Box>
          <Link href={pageSlug} onClick={() => trackProductClicked(isVarietyPack ? 'variety-pack' : slug)}>
            <Heading
              sx={{
                color: 'primary',
                fontFamily: 'heading',
                fontSize: ['16px', '18px'],
                lineHeight: '125%',
                mb: '8px',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {name}
            </Heading>
          </Link>
          <Text as="p" sx={{ color: 'primary', fontSize: ['12px'] }}>
            {translate('cart.n_pack', { amount: optionValues[0].presentation })}
          </Text>
          <Text as="p" sx={{ color: 'primary', fontSize: ['12px'] }}>
            {translate('cart.one_time_purchase')}
          </Text>
        </Box>
      </Grid>
      <Button
        onClick={e => handleAdd(sku)}
        sx={{
          border: '1px solid #FF5A47',
          backgroundColor: 'white',
          color: 'primary',
          width: '100%',
          fontSize: ['12px', '14px'],
          textAlign: 'center',
          fontFamily: 'heading',
          lineHeight: '16px',
          py: '10px',
          height: 'auto',
          '&:hover': {
            backgroundColor: 'primary',
            color: 'white'
          }
        }}
      >
        {translate('cart.quick_add', { amount: price })}
      </Button>
    </Flex>
  )
}
