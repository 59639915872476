/* @jsx jsx */
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'
import { CartProduct } from './CartProduct'
import useMatchMedia from '../../../hooks/utils/use-match-media'
import { toURL } from '~/utils/intl/localePrefix'

export const CartList = ({ isMobile }) => {
  const isTooSmall = useMatchMedia('(max-width: 1024px)')
  const { lineItems } = useCart().cart.data
  const translate = useTranslate()

  const [giftCards, setGiftCards] = useState([])
  const [giftCardsInfoSubmitted, setGiftCardsInfoSubmitted] = useState(
    giftCards?.map(item => ({ id: item.id, submitted: false })),
  )

  const updateGiftCardSubmission = id => {
    const updatedGiftCard = { id, submitted: true }
    const updatedObj = giftCardsInfoSubmitted.map(giftCard =>
      giftCard.id === id ? updatedGiftCard : giftCard,
    )
    setGiftCardsInfoSubmitted(updatedObj)
  }

  useEffect(() => {
    if (giftCards.length) {
      setGiftCardsInfoSubmitted(
        giftCards.map(item => ({ id: item.id, submitted: false })),
      )
    }
  }, [])

  useEffect(() => {
    if (lineItems) {
      setGiftCards(
        lineItems
          .filter(item => item.giftCards.length > 0)
          .map(x => x.giftCards[0]),
      )
    }
  }, [lineItems])

  return (
    <>
      <Flex
        sx={{
          height: '64px',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #FF5A4733',
          mt: '1rem',
        }}
      >
        <Heading
          sx={{
            color: 'primary',
            fontSize: ['16px', '20px'],
            lineHeight: '125%',
            textTransform: 'uppercase',
          }}
        >
          {translate('cart.your_cart')} ({lineItems?.length})
        </Heading>
        {!isTooSmall && (
          <Text
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              textAlign: 'right',
              fontFamily: 'heading',
              color: 'primary',
            }}
          >
            {translate('cart.not_ready')}{' '}
            <Link
              to={toURL('/shop')}
              sx={{ textDecoration: 'underline', color: 'primary' }}
            >
              {translate('cart.continue_shopping')}
            </Link>
          </Text>
        )}
      </Flex>

      <Box>
        {(lineItems || []).map(item => {
          return (
            <CartProduct
              key={item.id}
              {...item}
              isMobile={isMobile}
              updateGiftCardSubmission={updateGiftCardSubmission}
              giftCards={item.giftCards ? item.giftCards : null}
            />
          )
        })}
      </Box>
    </>
  )
}
