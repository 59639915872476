/* @jsx jsx */
import React from 'react'
import { jsx, Flex, Text, Button, Input, Spinner, Box } from 'theme-ui'
import {
  useCart,
  useTranslate,
  utils
} from '@chordcommerce/gatsby-theme-autonomy'
import { useForm } from 'react-hook-form'

export const CartPagePromoCode = () => {
  const { getAllCartPromotionsForDisplay } = utils
  const translate = useTranslate()
  const { addPromoCode, cart, removePromoCode } = useCart()

  const promotions = getAllCartPromotionsForDisplay(cart.data)
  const [showField, setShowField] = React.useState(false)
  const [apiError, setApiError] = React.useState(null)
  const activePromotion = promotions.some(promo => promo && promo.code)
  const activePromoCode = activePromotion ? promotions?.find(
    promo => !!promo && !!promo?.code
    ).code : undefined;

  const { register, handleSubmit } = useForm({
    defaultValues: {
      promoCode: activePromotion ? activePromoCode : ''
    }
  })
  const { isFetching } = cart.promoCode
  const toggleField = () => setShowField(prev => !prev)
  const hideField = () => setShowField(false)

  const onSubmit = async (data, e) => {
    try {
      setApiError(null)
      await addPromoCode(data.promoCode)
      hideField()
    } catch (error) {
      setApiError(error)
    }
  }

  const onClose = () => {
    hideField()
  }

  if (!showField && activePromotion) {
    return (
      <Box>
        <Text
          as="p"
          sx={{
            fontFamily: 'monospace',
            color: 'primary',
            lineHeight: '15px',
            fontSize: '12px',
            mb: '8px'
          }}
        >
          {translate('cart.promo_applied')}
        </Text>
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Flex sx={{ alignItems: 'center' }}>
            <img
              src="/images/promo_icon.png"
              alt="Promo Code"
              sx={{ width: '24px', height: '22px' }}
            />
            <Text
              as="p"
              sx={{
                color: 'primary',
                fontSize: '15px',
                lineHeight: '20px',
                fontFamily: 'heading',
                ml: '12px'
              }}
            >
              {activePromoCode}
            </Text>
            <Button
              variant="link"
              onClick={() => removePromoCode(activePromoCode)}
              sx={{
                width: 'auto',
                height: '100%',
                p: 0,
                m: 0,
                display: 'flex',
                alignItems: 'center',
                svg: {
                  '.invert': {
                    stroke: '#FF5A47'
                  },
                  ':hover': {
                    fill: '#FF5A47',
                    '.invert': {
                      stroke: '#F7EFD9'
                    }
                  }
                }
              }}
            >
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <clipPath id="clip0_387_487">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 14.1421) rotate(-45)"
                    />
                  </clipPath>
                  <clipPath id="clip1_387_487">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 14.1421) rotate(-45)"
                    />
                  </clipPath>
                </defs>
                <g clipPath="url(#clip0_387_487)">
                  <g clipPath="url(#clip1_387_487)">
                    <path
                      d="M20.0347 20.0346C23.289 16.7803 23.289 11.5039 20.0347 8.24953C16.7803 4.99516 11.5039 4.99516 8.24955 8.24953C4.99518 11.5039 4.99518 16.7803 8.24955 20.0346C11.5039 23.289 16.7803 23.289 20.0347 20.0346Z"
                      stroke="#FF5A47"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.7851 11.785L16.4991 16.4991"
                      className="invert"
                      // stroke="#FF5A47"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.7851 16.4991L16.4991 11.7851"
                      // stroke="#FF5A47"
                      className="invert"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </Button>
          </Flex>
        </Flex>
      </Box>
    )
  }

  if (!showField) {
    return (
      <Button
        sx={{ alignItems: 'center', display: 'flex', p: 0 }}
        variant="link"
        onClick={toggleField}
      >
        <img src="/images/promo_icon.png" alt="Promo Code" />
        <Text
          as="p"
          sx={{
            color: 'primary',
            textDecoration: 'underline',
            ml: '12px',
            fontSize: ['14px', '16px'],
            textTransform: 'capitalize',
            fontWeight: 400,
            fontFamily: 'heading'
          }}
        >
          {translate('cart.apply_promo_code')}
        </Text>
      </Button>
    )
  }

  return (
    <>
      <Flex>
        <Flex sx={{ position: 'relative' }}>
          <Input
            sx={{
              backgroundColor: 'white',
              color: 'primary',
              borderRadius: '10px 0px 0px 10px',
              fontFamily: 'heading',
              maxWidth: '240px',
              height: '100%',
              pl: '12px',
              fontSize: ['14px', '16px'],
              maxHeight: '50px',
              position: 'relative',
              textTransform: 'uppercase',
              '&::placeholder': {
                color: 'primary'
              },
              transition: 'all 0.2s ease-in-out',
              '&:focus': {
                boxShadow: 'inset 0 0 0 2px'
              }
            }}
            name="promoCode"
            aria-label={translate('cart.promo_code')}
            placeholder={translate('cart.promo_code')}
            ref={register({ required: true })}
          />

          <Button
            onClick={handleSubmit(onSubmit)}
            sx={{
              color: 'white',
              maxWidth: '112px',
              borderRadius: '0px 10px 10px 0px',
              fontSize: ['16px'],
              textTransform: 'uppercase'
            }}
          >
            {!isFetching && translate('cart.apply_promo_code_button')}
            {isFetching && <Spinner color="white" size={24} />}
          </Button>
        </Flex>
        <Button
          variant="link"
          sx={{
            color: 'primary',
            fontSize: ['16px'],
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}
          onClick={onClose}
        >
          Close
        </Button>
      </Flex>
      {!!apiError && (
        <Box pt={'8px'}>
          <Text
            sx={{
              fontSize: '12px',
              color: 'primary',
              lineHeight: '20px'
            }}
          >
            {apiError.message}
          </Text>
        </Box>
      )}
    </>
  )
}
