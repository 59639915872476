
/** @jsx jsx */
import React from 'react';
import { Flex, Text, Button, jsx } from 'theme-ui';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { toURL } from '~/utils/intl/localePrefix';

export const CartEmpty = ({ fluid, text, cta }) => {
  return (
    <Flex
      sx={{
        textAlign: 'center',
        width: '100%',
        alignItems: 'center',
        flexFlow: 'column nowrap',
        py: ['111px', '222px']
      }}
    >
      <Img
        fluid={fluid}
        alt={'Empty Cart'}
        imgStyle={{ width: '100%' }}
        style={{ width: '100%', maxWidth: 320, marginBottom: '48px' }}
      />
      <Text
        as="p"
        sx={{
          fontFamily: 'monospace',
          color: 'primary',
          fontWeight: 'bold',
          fontSize: '30px',
          lineHeight: 1,
          textAlign: 'center',
          maxWidth: 250,
          mb: '24px'
        }}
      >
        {text}
      </Text>
      <Link to={toURL("/shop")}>
        <Button sx={{ color: 'accent' }}>{cta}</Button>
      </Link>
    </Flex>
  )
}
