/** @jsx jsx */
import React from 'react'
import { Grid, Heading, jsx, Box } from 'theme-ui'
import { FreeShippingProgress } from '../FreeShippingProgress/FreeShippingProgress'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

export const CartHeader = ({ orderTotal, isMobile, showShippingProgress }) => {
  const translate = useTranslate()
  const isCanada =
    typeof window !== 'undefined' &&
    (window.location.pathname.includes('/en-CA') ||
      window.location.pathname.includes('/fr-CA'))
  return (
    <Grid
      sx={{
        position: 'relative',
        top: 0,
        left: 0,
        pt: ['8rem', '8rem'],
        mt: '-8rem',
        width: '100%',
        placeItems: 'center',
        backgroundColor: 'primary',
        color: 'accent',
        pb: isMobile ? '1.5rem' : '0',
      }}
    >
      <Heading
        sx={{
          font: ['400 40px / 40px Sofia', '900 60px / 80px Sofia'],
          textTransform: 'uppercase',
          zIndex: 1,
        }}
      >
        {translate('cart.page_title')}
      </Heading>
      {isMobile && !isCanada && showShippingProgress && (
        <Box
          sx={{
            position: 'relative',
            transform: 'translateY(30%)',
            width: '100%',
          }}
        >
          <FreeShippingProgress orderTotal={orderTotal} />
        </Box>
      )}
      <Grid
        sx={{
          position: 'absolute',
          width: '100%',
          pointerEvents: 'none',
          background: 'transparent',
          display: isMobile ? 'none' : 'initial',
          top: 0,
          bottom: '43%',
        }}
      >
        <svg
          viewBox="0 0 1440 376"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          sx={{
            maxHeight: '140px',
            width: '100%',
            position: 'absolute',
            top: '100%',
          }}
        >
          <path
            d="M-92 300.493C-69.5639 294.665 -34.752 287.774 9.80078 287.45C86.1314 286.895 114.556 315.895 201.346 318.022C286.3 320.057 304.584 294.156 390.336 292.075C540.762 288.606 583.398 374.356 728.554 375.975C890.557 377.779 956.428 280.558 1101.26 286.987C1207.94 291.705 1242.67 327.134 1333.61 325.7C1383.03 324.914 1418.16 312.194 1441 300.493C1441 223.577 1441 77.0086 1441 0L-92 0L-92 300.493Z"
            fill="#FF5A47"
          />
        </svg>
      </Grid>
    </Grid>
  )
}
