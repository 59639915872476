/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box, Text, Button, Spinner } from 'theme-ui'
import { CartUpsell } from './Upsell/CartUpsell'
import { CartList } from './CartList'
import { merchantPriceFormatter } from '../../../utils/merchant-feed/price-formatter'
import { CartPagePromoCode } from './CartPagePromoCode'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useRef } from 'react'

const MobileCartContent = ({
  promotions,
  handleCheckout,
  cmsCartData,
  creditAvailable,
  checkoutLoading,
  handleContinueShopping,
  updateGiftCardSubmission,
  giftCards,
  amountPrefix,
}) => {
  const { upsell } = cmsCartData

  const mobileCheckoutRef = useRef(null)
  const {
    cart: { data: order },
  } = useCart()

  const translate = useTranslate()

  const {
    itemTotal,
    displayTotalApplicableStoreCredit,
    displayOrderTotalAfterStoreCredit,
    displayStoreCreditRemainingAfterCapture,
  } = order

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          px: ['20px', '60px'],
          mx: 'auto',
          width: '100%',
          pb: mobileCheckoutRef.current?.offsetHeight || 0,
        }}
      >
        <CartList
          updateGiftCardSubmission={updateGiftCardSubmission}
          giftCards={giftCards}
          isMobile
        />
        <CartUpsell upsell={upsell} />
        <Box sx={{ pt: '24px', mb: '20px', position: 'relative' }}>
          <CartPagePromoCode />
        </Box>
        <div className="dk-flat-static"></div>
      </Box>
      <Box
        ref={mobileCheckoutRef}
        id="fixedCartCheckout"
        sx={{
          borderTop: '1px solid #FF5A47',
          px: ['20px', '60px'],
          py: '16px',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: 10,
          background: '#F7EFD9',
        }}
      >
        <Flex>
          <Text
            as="p"
            sx={{
              color: 'primary',
              fontWeight: 400,
              fontFamily: 'heading',
              fontSize: ['14px', '16px'],
              lineHeight: '20px',
            }}
          >
            {translate('cart.subtotal')}
          </Text>
          <Text
            as="p"
            sx={{
              ml: 'auto',
              color: 'primary',
              fontSize: ['14px', '16px'],
              lineHeight: '20px',
              textAlign: 'right',
            }}
          >
            ${merchantPriceFormatter.format(parseFloat(itemTotal))}
          </Text>
        </Flex>
        {promotions.map(({ label, amount }, i) => {
          const fmtLabel = label.match(/\((.*)\)/).pop()
          const priceParts = merchantPriceFormatter.format(amount).split('-')
          priceParts[0] = '-$'
          const fmtAmount = priceParts.join('')
          return (
            <Flex sx={{ mt: '8px' }} key={`promo_${i}`}>
              <Text
                as="p"
                sx={{
                  color: 'primary',
                  fontWeight: 400,
                  fontFamily: 'heading',
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
              >
                {fmtLabel}
              </Text>
              <Text
                as="p"
                sx={{
                  ml: 'auto',
                  color: '#007300',
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
              >
                {fmtAmount}
              </Text>
            </Flex>
          )
        })}
        {creditAvailable && (
          <>
            <Flex sx={{ mt: '16px' }}>
              <Text
                as="p"
                sx={{
                  color: 'primary',
                  fontWeight: 400,
                  fontFamily: 'heading',
                  fontSize: ['14px', '16px'],
                  lineHeight: '20px',
                }}
              >
                {translate('cart.store_credit_used')}
              </Text>
              <Text
                as="p"
                sx={{
                  ml: 'auto',
                  color: '#007300',
                  fontSize: ['14px', '16px'],
                  lineHeight: '20px',
                }}
              >
                {displayTotalApplicableStoreCredit}
              </Text>
            </Flex>
            <Flex sx={{ mt: '16px' }}>
              <Text
                as="p"
                sx={{
                  color: 'primary',
                  fontWeight: 400,
                  fontFamily: 'heading',
                  fontSize: ['14px', '16px'],
                  lineHeight: '20px',
                }}
              >
                {translate('cart.store_credit_remaining')}
              </Text>
              <Text
                as="p"
                sx={{
                  ml: 'auto',
                  color: '#007300',
                  fontSize: ['14px', '16px'],
                }}
              >
                {displayStoreCreditRemainingAfterCapture}
              </Text>
            </Flex>
          </>
        )}
        <Button
          sx={{
            backgroundColor: 'secondary',
            color: '#FFDD00',
            width: '100%',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
            mt: '16px',
            height: ['50px', '56px'],
            fontFamily: 'heading',
            '&:hover': {
              backgroundColor: 'primary',
              color: 'white',
            },
          }}
          onClick={handleCheckout}
        >
          {!checkoutLoading ? (
            <>
              {translate('cart.checkout')} <span sx={{ px: '4px' }}>•</span>{' '}
              {amountPrefix}
              {displayOrderTotalAfterStoreCredit}
            </>
          ) : (
            <Spinner color="#FFDD00" size="15" />
          )}
        </Button>
        <Button
          variant="link"
          sx={{
            textAlign: 'center',
            mt: '20px',
            fontSize: '14px',
            lineHeight: '20px',
            width: '100%',
            fontFamily: 'heading',
            py: 0,
            mb: '8px',
          }}
          onClick={handleContinueShopping}
        >
          Continue Shopping
        </Button>
      </Box>
    </Box>
  )
}

export default MobileCartContent
